/* .contact-form {
  max-width: 800px;
  margin: auto;

  width: 90%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  font-size: 1.3rem;
} */
.contact-input-field {
  padding: 1rem;
  width: 100%;
  border: 1px solid rgba(255, 174, 0, 0.3);
  border-radius: 0.4rem;
}
.select-field {
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(255, 174, 0, 0.3);
  border-radius: 0.4rem;
}
.contact-message {
  font-size: 0.9rem;
  color: black;
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(255, 174, 0, 0.3);
  border-radius: 0.4rem;
}
.contact-sbt-button {
  width: 100%;
  padding: 16px;
  background-color: rgb(255, 174, 0);
  color: white;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}
.contact-sbt-button:hover {
  background-color: rgb(255, 174, 0, 0.8);
}
