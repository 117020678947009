.seat-button {
  /* width: 40px; */
  /* height: 40px; */
  border-radius: 3px 3px 20px 20px;
  background-color: #69d269;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-reserved {
  /* background-color: #d9534f; */
  background-color: #ccc;
  cursor: not-allowed;
}
.btn-selected {
  background-color: #3151e1;
}

.btn-paid {
  background-color: #d22d0c;
  cursor: not-allowed;
}

.btn-available:hover {
  background-color: #f0ad4e;
}

.seat-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.seat-selection-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  font-size: 20px;
  font-weight: bold;
}
/* .seat-selection {
  background-color: #f2f2f2;
  padding: 30px;
  border-radius: 10px;
}

.aisle {
  background-color: #fff;
  height: 200px;
  width: 30px;
}

.seat-button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #5cb85c;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-reserved {
  background-color: #d9534f;
}

.btn-available:hover {
  background-color: #f0ad4e;
} */
