@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
/* *{
  margin:0;
  padding: 0;
  font-family: sans-serif;
} */
.ticket-print {
  font-family: sans-serif;
}
.bold {
  font-weight: bold;
}
.gray-color {
  color: rgb(148, 148, 148);
}
.gray-bg-color {
  background-color: rgb(233, 231, 231);
}
.container {
  max-width: 1300px;
  margin: 3rem auto;
}
.flight-header {
  display: flex;
  gap: 2rem;
}
.passenger-header {
  display: flex;
  justify-content: space-between;
}
.reservaiton-p {
  font-size: 1.1rem;
}
.reservaiton-p span {
  font-size: 1rem;
  font-weight: bold;
}
