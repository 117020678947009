.line {
  width: 100%;
  height: 0.1rem;
  color: gray;
  background-color: rgb(82, 81, 81);
}
.dep-daycard {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  /* margin-top: 2rem; */
  /* margin-bottom: 0.5rem; */
}
.dep-daycard h2 {
  font-weight: lighter;
}
.pass-det {
  padding: 0.5rem;
}
.pass-details-card {
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-gap: 0.3rem;
  height: 210px;
}
.pass-details-card h2 {
  font-weight: lighter;
}
.pass-det-time {
  display: flex;
}
.pass-det-2 {
  border-right: dotted gray 1px;
  width: 300px;
}
.pass-det-2-top {
  display: flex;
  /* gap: 6rem; */
  height: min-content;
  padding: 0.5rem;
  border-bottom: dotted gray 1px;
}
.pass-det-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.pass-det-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  border: solid rgb(163, 162, 162) 2px;
}
.dep-time {
  border-right: dotted gray 1px;
  padding: 0.5rem;
}
.arr-time {
  padding: 0.5rem;
}
.pass-plane {
  padding: 0.5rem;
}
.bottom-pass-details {
  /* margin: 1rem 0; */
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}
.bottom-pass-details p {
}
